<template>
	<div id="holoverz-screen">
		<jumbotron
			:logoSrc="'/images/logos/holoverz-logo-full.svg'"
			:description="$t('holoverzScreen.welcome.description')"
			:storesLabel="$t('holoverzScreen.welcome.storesLabel')"
			:textColor="'rgba(38, 42, 82, 1)'"
			:backgroundImage="'/images/sections/holoverz/desktop/holoverz_header.png'"
			:mobileBackgroundImage="'/images/sections/holoverz/mobile/holoverz_header.png'"
		>
			<template v-slot:chart>
				<chart-component
					v-if="refactoredData"
					:titleText="$t('holoverzScreen.welcome.chart.title')"
					:subtitleText="
						$t('holoverzScreen.welcome.chart.subtitle', {
							value: calculatedLastPrice,
						})
					"
					:infoText="$t('holoverzScreen.welcome.chart.infoText')"
					:chart-data="refactoredData"
					:gradientStartColor="'rgba(247, 87, 215, 0.2)'"
					:gradientEndColor="'rgba(247, 87, 215, 0)'"
					:infoTooltipBackground="'#4B2EAA'"
				/>
			</template>
		</jumbotron>
		<div id="holoverz-collection">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-md-6 col-12 d-flex d-md-flex justify-content-center">
						<img
							class="wallet-image d-none d-md-block"
							src="/images/sections/holoverz/desktop/holoverz_card.png"
						/>
					</div>
					<div class="col-md-6 col-12">
						<div class="title headline-l">
							{{ $t('holoverzScreen.collection.title') }}
						</div>
						<img
							class="main-image d-block d-md-none"
							src="/images/sections/holoverz/mobile/holoverz_card.png"
						/>
						<div class="text text-l mt-4">
							{{ $t('holoverzScreen.collection.description') }}
						</div>
						<base-button
							class="more-button mt-4"
							:text="$t('holoverzScreen.collection.btn')"
							size="small"
							type="primary"
							@clicked="handleCardButtonClick()"
						/>
					</div>
				</div>
			</div>
		</div>
		<advantages-section
			:backgroundColor="'#fff'"
			:advantagesTitle="$t('holoverzScreen.advantages.title')"
			:advantages="advantages"
		/>
		<div id="payment">
			<div class="bg">
				<div class="content">
					<h1>{{ $t('holoverzScreen.payment.title') }}</h1>
					<h2>{{ $t('holoverzScreen.payment.subtitle') }}</h2>
					<p>{{ $t('holoverzScreen.payment.description') }}</p>
					<p>{{ $t('holoverzScreen.payment.description_2') }}</p>
				</div>
			</div>
		</div>
		<div id="exchange_rate">
			<div class="container d-none d-md-flex">
				<div class="img_container col-5">
					<img
						src="/images/sections/holoverz/desktop/increasing_exchange_rate.png"
					/>
				</div>
				<div class="content col-7">
					<h1>{{ $t('holoverzScreen.exchange_rate.title') }}</h1>
					<h2>{{ $t('holoverzScreen.exchange_rate.subtitle') }}</h2>
					<p class="mt-1">
						{{ $t('holoverzScreen.exchange_rate.description_1') }}
					</p>
					<p class="mt-4">
						{{ $t('holoverzScreen.exchange_rate.description_2') }}
					</p>
					<p
						class="mt-4"
						v-html="$t('holoverzScreen.exchange_rate.description_3')"
					></p>
				</div>
			</div>
			<div class="d-block d-md-none text-center">
				<h1 class="m-auto">{{ $t('holoverzScreen.exchange_rate.title') }}</h1>
				<div class="img_container mt-4">
					<img
						src="/images/sections/holoverz/mobile/increasing_exchange_rate.png"
						class="w-100"
					/>
				</div>
				<div class="content px-3">
					<h2>{{ $t('holoverzScreen.exchange_rate.subtitle') }}</h2>
					<h2>{{ $t('holoverzScreen.exchange_rate.description_1') }}</h2>
					<p>{{ $t('holoverzScreen.exchange_rate.description_2') }}</p>
					<p class="mt-4">
						{{ $t('holoverzScreen.exchange_rate.description_3') }}
					</p>
				</div>
			</div>
		</div>
		<details-section
			:backgroundColor="'#fff'"
			:boxOptions="boxOptions"
			:faqTitle="$t('holoverzScreen.details.faq.title')"
			:faq="faq"
		/>
		<div id="buy" class="container">
			<h1>{{ $t('holoverzScreen.buy.title') }}</h1>
			<div class="row">
				<div class="box col col-12 col-md-6">
					<div class="step">
						<p>1.</p>
					</div>
					<div class="description">
						{{ $t('holoverzScreen.buy.first.description') }}
					</div>
				</div>
				<div class="box col col-12 col-md-6">
					<div class="step">
						<p>2.</p>
					</div>
					<div class="description">
						{{ $t('holoverzScreen.buy.second.description') }}
					</div>
				</div>
				<div class="box col col-12 col-md-6">
					<div class="step">
						<p>3.</p>
					</div>
					<div class="description">
						{{ $t('holoverzScreen.buy.third.description') }}
					</div>
				</div>
				<div class="box col col-12 col-md-6">
					<div class="step">
						<p>4.</p>
					</div>
					<div class="description">
						{{ $t('holoverzScreen.buy.fourth.description') }}
					</div>
				</div>
			</div>
		</div>
		<details-section
			class="fees-section"
			:backgroundColor="'#fff'"
			:boxOptions="boxOptions"
			:feesTitle="$t('holoverzScreen.details.fees.title')"
			:fees="fees"
		/>
		<details-section
			class="limits-section"
			:backgroundColor="'#fff'"
			:boxOptions="boxOptions"
			:limitationsTitle="$t('holoverzScreen.details.limitations.title')"
			:limitations="limitations"
		/>
		<div id="about" class="container">
			<h1>{{ $t('holoverzScreen.about.title') }}</h1>
			<div class="info_box_container mt-5">
				<div class="info_box">
					<h2>{{ $t('holoverzScreen.about.first.title') }}</h2>
					<p v-html="$t('holoverzScreen.about.first.description')" />
				</div>
				<div class="info_box">
					<h2>{{ $t('holoverzScreen.about.second.title') }}</h2>
					<p v-html="$t('holoverzScreen.about.second.description')" />
				</div>
				<div class="info_box">
					<h2>{{ $t('holoverzScreen.about.third.title') }}</h2>
					<p v-html="$t('holoverzScreen.about.third.description')" />
				</div>
			</div>
		</div>
		<div id="download">
			<div class="bg">
				<div class="content">
					<img src="/images/logos/holoverz-logo-full.svg" />
					<base-button
						:text="$t('holoverzScreen.download.paperButtonText')"
						size="large"
						@clicked="handleButtonClick()"
					/>
					<h1>{{ $t('holoverzScreen.download.title') }}</h1>
					<div class="stores">
						<div class="row">
							<div class="col-6"><store-button :version="'apple'" /></div>
							<div class="col-6"><store-button :version="'google'" /></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<warning-section />
		<other-products :hiddenProductKey="'bit'" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Api from '@/api';
import moment from 'moment';
import Jumbotron from '../../components/elements/Jumbotron.vue';
import AdvantagesSection from '@/components/sections/bit/AdvantagesSection';
import DetailsSection from '@/components/sections/bit/DetailsSection';
import OperationSection from '@/components/sections/bit/OperationSection';
import ReportSection from '@/components/sections/bit/ReportSection';
import DownloadSection from '@/components/sections/bit/DownloadSection';
import BitFamilySection from '@/components/sections/bit/BitFamilySection';
import WarningSection from '@/components/sections/bit/WarningSection';
import OtherProducts from '@/components/sections/OtherProducts';
import ChartComponent from '@/components/elements/ChartComponent';

export default {
	name: 'HoloverzScreen',
	components: {
		Jumbotron,
		AdvantagesSection,
		DetailsSection,
		OperationSection,
		ReportSection,
		DownloadSection,
		BitFamilySection,
		WarningSection,
		OtherProducts,
		ChartComponent,
	},
	data: () => ({
		chartDataLimit: 7,
		chartDataInterval: '1m',
		chartData: undefined,
		advantages: [
			{
				iconUrl: '/images/icons/inimitable.svg',
				labelKey: 'holoverzScreen.advantages.labels.inimitable',
				descriptionKey: 'holoverzScreen.advantages.descriptions.inimitable',
			},
			{
				iconUrl: '/images/icons/dynamic.svg',
				labelKey: 'holoverzScreen.advantages.labels.dynamic',
				descriptionKey: 'holoverzScreen.advantages.descriptions.dynamic',
			},
			{
				iconUrl: '/images/icons/information_storage.svg',
				labelKey: 'holoverzScreen.advantages.labels.informationStorage',
				descriptionKey:
					'holoverzScreen.advantages.descriptions.informationStorage',
			},
			{
				iconUrl: '/images/icons/quality.svg',
				labelKey: 'holoverzScreen.advantages.labels.quality',
				descriptionKey: 'holoverzScreen.advantages.descriptions.quality',
			},
			{
				iconUrl: '/images/icons/colorful.svg',
				labelKey: 'holoverzScreen.advantages.labels.colorful',
				descriptionKey: 'holoverzScreen.advantages.descriptions.colorful',
			},
			{
				iconUrl: '/images/icons/licensed.svg',
				labelKey: 'holoverzScreen.advantages.labels.licensed',
				descriptionKey: 'holoverzScreen.advantages.descriptions.licensed',
			},
			{
				iconUrl: '/images/icons/one_of_a_kind.svg',
				labelKey: 'holoverzScreen.advantages.labels.oneOfAKind',
				descriptionKey: 'holoverzScreen.advantages.descriptions.oneOfAKind',
			},
			{
				iconUrl: '/images/icons/flexible.svg',
				labelKey: 'holoverzScreen.advantages.labels.flexible',
				descriptionKey: 'holoverzScreen.advantages.descriptions.flexible',
			},
		],
		boxOptions: {
			borderColor: 'rgba(123, 208, 255, 1)',
			borderWidth: 15,
			boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
			hasBorderAround: false,
			background:
				'linear-gradient(111.11deg, #FFFFFF -3.09%, #FEFEFE 53.29%, #F7F7F7 103.03%)',
		},
		faq: [
			{
				title: 'holoverzScreen.details.faq.parts.first.value',
				values: [
					{
						value: '',
						label: 'holoverzScreen.details.faq.parts.first.description',
					},
				],
			},
			{
				title: 'holoverzScreen.details.faq.parts.second.value',
				values: [
					{
						value: '',
						label: 'holoverzScreen.details.faq.parts.second.description',
					},
				],
			},
		],
		fees: [
			{
				title: 'holoverzScreen.details.fees.parts.buying.title',
				values: [
					{
						value: '',
						label: 'holoverzScreen.details.fees.parts.buying.list.first.label',
					},
				],
			},
			{
				title: 'holoverzScreen.details.fees.parts.transfer.title',
				values: [
					{
						value: '',
						label:
							'holoverzScreen.details.fees.parts.transfer.list.first.label',
					},
				],
			},
			{
				title: 'holoverzScreen.details.fees.parts.sell.title',
				values: [
					{
						value: '',
						label: 'holoverzScreen.details.fees.parts.sell.list.first.label',
					},
				],
			},
			{
				title: 'holoverzScreen.details.fees.parts.pay.title',
				values: [
					{
						value: '',
						label: 'holoverzScreen.details.fees.parts.pay.list.first.label',
					},
				],
			},
		],
		limitations: [
			{
				title: 'holoverzScreen.details.limitations.parts.first.title',
				values: [
					{
						value: '',
						label:
							'holoverzScreen.details.limitations.parts.first.list.first.label',
					},
				],
			},
		],
	}),
	computed: {
		...mapGetters({
			getSelectedLanguage: 'settings/getSelectedLanguage',
		}),
		calculatedLastPrice() {
			if (this.chartData) {
				return this.chartData[this.chartData.length - 1].rate.toFixed(2);
			}
			return undefined;
		},
		refactoredData() {
			if (this.chartData) {
				return {
					labels: this.chartData.map((obj) => {
						moment.locale(this.getSelectedLanguage);
						const newDate = moment(new Date(obj.date))
							.format('MMM')
							.replace('.', '');
						return newDate.toLowerCase();
					}),
					tooltipLabel: (value) => `€${value.yLabel.toFixed(2)} / Holoverz`,
					datasets: [
						{
							data: this.chartData.map((obj) => obj.rate),
							borderColor: 'rgba(247, 87, 215, 1)',
							pointBackgroundColor: 'rgba(255, 255, 255, 1)',
							pointBorderColor: 'rgba(75, 46, 170, 1)',
							borderCapStyle: 'round',
							pointHoverBackgroundColor: '#fff',
							pointHoverBorderColor: 'rgba(75, 46, 170, 1)',
							pointHighlightFill: '#fff',
							pointHoverBorderWidth: 2,
							backgroundColor: 'transparent',
							pointColor: '#fff',
							pointStrokeColor: '#242A55',
						},
					],
				};
			}
			return undefined;
		},
	},
	async mounted() {
		const response = await Api.get(
			`publicReferencePrice/3DHT?limit=${this.chartDataLimit}&interval=${this.chartDataInterval}`,
		);
		this.chartData = await response.json();

		const res = await Api.get('publicPrice');
		this.currentPrice = await res.json();

		let currentProduct = this.currentPrice.find((price) => {
			return price.asset === '3DHT' && price.type === 'Buy';
		});

		if (currentProduct) {
			this.chartData[this.chartData.length - 1].rate = currentProduct.rate;
		}
	},
	methods: {
		handleButtonClick() {
			if (this.$i18n.locale === 'hu') {
				window.open(
					'https://data.blockben.com/whitepaper/Holoverz_Lightpaper_HU.pdf',
					'_blank',
				);
			} else {
				window.open(
					'https://data.blockben.com/whitepaper/Holoverz_Lightpaper_EN.pdf',
					'_blank',
				);
			}
		},
		handleCardButtonClick() {
			this.$router.push({ path: '/holoverz-collections' });
		},
	},
};
</script>

<style lang="scss" scoped>
.jumbotron {
	&.has-bgimage {
		@media (max-width: $breakpoint-xs) {
			background-position: center center;
		}
	}

	&::v-deep {
		.jumbotron-container {
			height: 655px;
		}

		.jumbotron-row {
			@media (max-width: $breakpoint-sm) {
				height: 100% !important;
			}
		}

		.top-section {
			padding: 0;
		}

		.description {
			margin: 25px 0 40px;
		}

		.stores-label {
			text-decoration: underline;
		}
	}
}

.reports-section {
	margin: 190px 0 0;
}

.advantages-section {
	margin: 70px 0 30px 0;
	padding: 0;
}

.details-section {
	&:not(.limits-section) {
		&::v-deep {
			.fees {
				padding: 0;
			}
		}
	}
	&::v-deep {
		section.division {
			.bordered-box {
				padding-right: 55px;
			}
		}
	}
}

.fees-section {
	padding: 60px 0;
}

@media (max-width: 768px) {
	.bitFamily-section {
		padding-top: 100px;
	}
}

.limits-section {
	padding: 60px 0 0;

	&::v-deep {
		.division {
			padding: 0;
		}

		.informations-wrapper {
			.info-text {
				max-width: 800px;
			}
		}

		.description-wrapper {
			li .label .prefix {
				display: none;
			}
		}
	}
}

.operation-section {
	padding: 0 0 130px;
}

.other-products {
	&::v-deep {
		.products-wrapper {
			padding: 0;
			column-gap: 30px;
		}
	}
}

@media (max-width: $breakpoint-sm) {
	#chart-component {
		box-shadow: 0px 4px 8px rgba(36, 42, 85, 0.1);
		z-index: 1;
	}
}

.introduction-section {
	@media (max-width: $breakpoint-sm) {
		padding-top: 0;

		&::v-deep {
			.illustration {
				width: 100%;
				height: auto;
			}

			.content-col {
				padding-top: 30px;
			}
		}
	}
}

.details-section {
	&::v-deep {
		.section-title {
			@media (max-width: $breakpoint-sm) {
				margin: 0 0 45px;
				text-align: left;
				padding-left: 0;
			}
		}
	}
}

#payment {
	color: rgba(38, 42, 82, 1);
	text-align: center;

	.bg {
		background-image: url('/images/sections/holoverz/desktop/new_payment.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding: 60px 0;

		@media (max-width: $breakpoint-xs) {
			background-image: url('/images/sections/holoverz/mobile/new_payment.png');
		}
	}

	.content {
		max-width: 1000px;
		margin: auto;
		padding: 0 30px;
	}

	h1 {
		font-size: 40px;
		line-height: 52px;
		font-family: 'HKNova-Bold', sans-serif;
		margin-bottom: 40px;
	}

	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		max-width: 790px;
		margin: 20px auto 0 auto;
	}

	h2 {
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
	}
}

#exchange_rate {
	margin: 100px 0;

	@media (max-width: $breakpoint-xs) {
		margin: 32px 0;
	}
	h1 {
		font-size: 40px;
		line-height: 52px;
		font-family: 'HKNova-Bold', sans-serif;
		color: rgba(38, 42, 82, 1);
		max-width: 450px;
	}

	h2 {
		font-size: 16px;
		line-height: 30px;
		font-weight: 700;
		color: rgba(70, 102, 143, 1);
		margin-top: 30px;
	}

	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 30px;
		color: rgba(70, 102, 143, 1);
	}
}

#about {
	margin-bottom: 80px;

	h1 {
		color: rgba(38, 42, 82, 1);
		font-size: 40px;
		line-height: 52px;
		font-family: 'HKNova-Bold', sans-serif;
	}

	h2 {
		color: rgba(70, 102, 143, 1);
		font-size: 18px;
		line-height: 24px;
		font-weight: 700;
		margin-bottom: 32px;
	}

	P {
		color: rgba(70, 102, 143, 1);
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
	}

	.info_box_container {
		display: flex;
		gap: 30px;
		flex-wrap: wrap;
		justify-content: center;
	}
	.info_box {
		max-width: 350px;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
		border: 1px solid rgba(246, 244, 255, 1);
		padding: 40px 20px;
	}
}

#holoverz-collection {
	margin: 100px 0;
	@media (max-width: 768px) {
		margin: 50px 0;
		text-align: center;
		img {
			margin: 30px auto 0 auto;
		}
	}
}

#download {
	.bg {
		background-image: url('/images/sections/holoverz/desktop/download.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding: 60px 0;

		@media (max-width: $breakpoint-xs) {
			background-image: url('/images/sections/holoverz/mobile/download.png');
		}
	}

	.content {
		padding: 60px 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 40px;
		color: rgba(255, 255, 255, 1);
		max-width: 730px;
		margin: auto;
		text-align: center;

		h1 {
			font-size: 30px;
			line-height: 40px;
			color: #fff;
			font-weight: 700;
		}
	}
}

#buy {
	margin-top: 80px;
	h1 {
		color: rgba(38, 42, 82, 1);
		font-size: 40px;
		line-height: 52px;
		font-family: 'HKNova-Bold', sans-serif;
		margin-bottom: 20px;
	}
	.box {
		display: flex;
		border-radius: 8px;
		align-items: center;
		gap: 30px;
		width: 540px;
		border: 1px solid rgba(246, 244, 255, 1);
		margin-top: 30px;

		.description {
			font-size: 16px;
			line-height: 24px;
			font-weight: 400;
			color: rgba(70, 102, 143, 1);
			max-width: 70%;
		}
		.step {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 95px;
			width: 65px;
			background-color: rgba(123, 208, 255, 1);
			border-radius: 8px 0 0 8px;

			p {
				color: rgba(70, 102, 143, 1);
				font-size: 25px;
				line-height: 45.5px;
				font-weight: 700px;
			}
		}
	}
}
</style>